body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 1.5;
  padding: 0;
  margin: 0;

  color: #D1d0CF;
  background: #141414;
}
